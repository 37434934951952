import React from 'react';

const Badges = () => {
    return (
        <div className="row mb-5">
            <div className="col-12 col-lg-8">
                <p>
                    <span className="badge badge-primary mr-2 mb-4">
                        Primary
                    </span>
                    <span className="badge badge-secondary mr-2 mb-4">
                        Secondary
                    </span>
                    <span className="badge badge-success mr-2 mb-4">
                        Success
                    </span>
                    <span className="badge badge-danger mr-2 mb-4">Danger</span>
                    <span className="badge badge-warning mr-2 mb-4">
                        Warning
                    </span>
                    <span className="badge badge-info mr-2 mb-4">Info</span>
                    <span className="badge badge-light mr-2 mb-4">Light</span>
                    <span className="badge badge-dark mr-2 mb-4">Dark</span>
                </p>
                <p>
                    <span className="badge badge-pill-round badge-primary mr-2">
                        0
                    </span>
                    <span className="badge badge-pill-round badge-secondary mr-2">
                        1
                    </span>
                    <span className="badge badge-pill-round badge-success mr-2">
                        2
                    </span>
                    <span className="badge badge-pill-round badge-danger mr-2">
                        12
                    </span>
                    <span className="badge badge-pill-round badge-warning mr-2">
                        123
                    </span>
                    <span className="badge badge-pill-round badge-info mr-2">
                        1
                    </span>
                    <span className="badge badge-pill-round badge-light mr-2">
                        1
                    </span>
                    <span className="badge badge-pill-round badge-dark mr-2">
                        1
                    </span>
                </p>
                <p>
                    <span className="badge badge-pill-round badge-secondary">
                        1
                    </span>
                    <span className="badge badge-pill-round badge-success">
                        23
                    </span>
                </p>
            </div>
            <div className="col-12 col-lg-4">
                <h1>
                    Heading <span className="badge badge-secondary">badge</span>
                </h1>
            </div>
        </div>
    );
};

export default Badges;
