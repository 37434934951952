import React from 'react';

const Alerts = () => {
    return (
        <div className="row mb-5">
            <div className="col-12 col-lg-6">
                <div className="alert alert-primary" role="alert">
                    Primary alert
                </div>
                <div className="alert alert-secondary" role="alert">
                    Secondary alert
                </div>
                <div className="alert alert-success" role="alert">
                    Success alert
                </div>
                <div className="alert alert-danger" role="alert">
                    Danger alert
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div className="alert alert-warning" role="alert">
                    Warning alert
                </div>
                <div className="alert alert-info" role="alert">
                    Info alert
                </div>
                <div className="alert alert-light" role="alert">
                    Light alert
                </div>
                <div className="alert alert-dark" role="alert">
                    Dark alert
                </div>
            </div>
        </div>
    );
};

export default Alerts;
