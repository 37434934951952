import React from 'react';

import Page from '../components/page';
import Alerts from '../components/display/alerts';
import Badges from '../components/display/badges';
import FormControls from '../components/display/form-controls';
import Icons from '../components/display/icons';
import Lists from '../components/display/lists';
import Navigation from '../components/display/navigation';
import Tooltips from '../components/display/tooltips';
import Typography from '../components/display/typography';

const IndexPage = () => (
    <Page>
        <h2 className="text-styled no-gatsby">Typography</h2>
        <Typography />

        <h2 className="text-styled my-5 no-gatsby">Form controls</h2>
        <FormControls />

        <h2 className="text-styled my-5 no-gatsby">Icons</h2>
        <Icons />

        <h2 className="text-styled my-5 no-gatsby">Alerts</h2>
        <Alerts />

        <h2 className="text-styled my-5 no-gatsby">Tooltips</h2>
        <Tooltips />

        <h2 className="text-styled my-5 no-gatsby">Badges</h2>
        <Badges />

        <h2 className="text-styled my-5 no-gatsby">Navigation</h2>
        <Navigation />

        <h2 className="text-styled my-5 no-gatsby">Lists</h2>
        <Lists />
    </Page>
);

export default IndexPage;
