import React, { Fragment } from 'react';

const Tooltips = () => {
    return (
        <Fragment>
            <div className="row mb-5 pb-4">
                <div className="col-12 col-lg-3 mb-lg-0 mb-5">
                    <p>
                        <span className="tooltip_container">
                            <a href="/">Hover to show tooltip</a>
                            <span className="tooltip">
                                Lorem ipsum dolor sit amet
                            </span>
                        </span>
                    </p>
                </div>
                <div className="col-12 col-lg-3 mb-lg-0 mb-5">
                    <p className="tooltip_container tooltip-focus tooltip-no-hover">
                        <input
                            type="text"
                            className="form-control tooltip-focus-target"
                            placeholder="Focus to show tooltip"
                        />
                        <span className="tooltip">
                            Lorem ipsum dolor sit amet
                        </span>
                    </p>
                </div>
                <div className="col-12 col-lg-3 mb-lg-0 mb-5 text-center mt-lg-0 mt-3">
                    <p className="tooltip_container tooltip-toggle tooltip-toggle-show tooltip-no-hover d-inline-block">
                        <button className="btn btn-primary btn-primary">
                            Tooltip toggled on
                        </button>
                        <span className="tooltip">
                            Lorem ipsum dolor sit amet
                        </span>
                    </p>
                </div>
                <div className="col-12 col-lg-3 text-center mt-lg-0 mt-3">
                    <div className="tooltip_container tooltip-toggle tooltip-toggle-show">
                        <button className="btn btn-primary">
                            HTML tooltip
                        </button>
                        <div className="tooltip tooltip-lg">
                            <div className="tooltip_html">
                                <svg
                                    className="tooltip_icon icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34">
                                    <path d="M7 12c0-2.755.978-5.11 2.934-7.066C11.89 2.978 14.245 2 17 2s5.11.978 7.066 2.934C26.022 6.89 27 9.245 27 12c0 1.273-.214 2.46-.642 3.559-.429 1.1-.95 2.049-1.563 2.847a66.218 66.218 0 0 1-1.84 2.292 11.85 11.85 0 0 0-1.563 2.396c-.428.868-.642 1.753-.642 2.656h-7.5c0-.903-.214-1.788-.642-2.656a11.85 11.85 0 0 0-1.563-2.396 66.218 66.218 0 0 1-1.84-2.292c-.614-.798-1.134-1.747-1.563-2.847C7.214 14.459 7 13.273 7 12zm13.75 16.25v1.875c0 .51-.185.95-.556 1.32-.37.37-.81.555-1.319.555h-3.75a1.8 1.8 0 0 1-1.32-.556c-.37-.37-.555-.81-.555-1.319V28.25h7.5z" />
                                </svg>
                                <span className="tooltip_content">
                                    <strong>Lorem ipsum</strong> dolor{' '}
                                    <em>sit amet</em>.
                                </span>
                                <svg
                                    className="tooltip_close-icon icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34">
                                    <path d="M29.828 24.58c.115.16.172.31.172.446 0 .138-.057.286-.172.446l-4.356 4.356c-.16.115-.308.172-.446.172-.137 0-.285-.057-.446-.172L17 22.248l-7.58 7.58c-.16.115-.31.172-.446.172-.138 0-.286-.057-.446-.172l-4.356-4.356c-.115-.16-.172-.308-.172-.446 0-.137.057-.285.172-.446l7.58-7.58-7.58-7.58C4.057 9.26 4 9.11 4 8.974c0-.138.057-.286.172-.446l4.356-4.356c.16-.115.308-.172.446-.172.137 0 .285.057.446.172l7.58 7.58 7.58-7.58c.16-.115.31-.172.446-.172.138 0 .286.057.446.172l4.356 4.356c.115.16.172.308.172.446 0 .137-.057.285-.172.446L22.248 17l7.58 7.58z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Tooltips;
