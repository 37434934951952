import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import HeartIcon from '../../../theme/icons2/heart-fill.svg';
import LoadIcon from '../../../theme/icons2/loading.svg';
import StarIcon from '../../../theme/icons2/star-fill.svg';

const Icons = (props) => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    allFile(
                        filter: {
                            sourceInstanceName: {
                                regex: "/icons2|icons-colored|icons-animated/"
                            }
                        }
                    ) {
                        edges {
                            node {
                                name
                                publicURL
                            }
                        }
                    }
                }
            `}
            render={(data) => <IconDisplay data={data} {...props} />}
        />
    );
};

const IconDisplay = (props) => {
    const icons = props.data.allFile
        ? props.data.allFile.edges.map((edge) => edge.node)
        : [];

    const heartIcon = icons.find((icon) => icon.name === 'heart');
    const loadIcon = icons.find((icon) => icon.name === 'currency');
    const starIcon = icons.find((icon) => icon.name === 'star');

    return (
        <>
            <div className="row mb-5">
                {icons.map((icon, i) => (
                    <div
                        key={icon.name + i}
                        className="col-1 p-2 text-center tooltip_container">
                        <img
                            src={icon.publicURL}
                            className="icon icon-lg"
                            alt=""
                        />
                        <span className="tooltip tooltip-sm ">{icon.name}</span>
                    </div>
                ))}
            </div>
            <div className="row">
                <div className="col-12 col-lg-3">
                    <HeartIcon
                        src={heartIcon.publicURL}
                        className="icon icon-xs"
                    />
                    <HeartIcon
                        src={heartIcon.publicURL}
                        className="icon icon-sm"
                    />
                    <HeartIcon
                        src={heartIcon.publicURL}
                        className="icon icon-md"
                    />
                    <HeartIcon
                        src={heartIcon.publicURL}
                        className="icon icon-lg"
                    />
                    <HeartIcon
                        src={heartIcon.publicURL}
                        className="icon icon-xl"
                    />
                    <p>Sizes</p>
                </div>
                <div className="col-12 col-lg-3">
                    <StarIcon
                        src={starIcon.publicURL}
                        className="icon icon-primary"
                    />
                    <StarIcon
                        src={starIcon.publicURL}
                        className="icon icon-secondary"
                    />
                    <StarIcon
                        src={starIcon.publicURL}
                        className="icon icon-branded"
                    />
                    <StarIcon
                        src={starIcon.publicURL}
                        className="icon icon-success"
                    />
                    <StarIcon
                        src={starIcon.publicURL}
                        className="icon icon-danger"
                    />
                    <StarIcon
                        src={starIcon.publicURL}
                        className="icon icon-white"
                    />
                    <StarIcon
                        src={starIcon.publicURL}
                        className="icon icon-dark"
                    />
                    <p>Colors</p>
                </div>
                <div className="col-12 col-lg-3">
                    <LoadIcon
                        src={loadIcon.publicURL}
                        className="icon icon-spin"
                    />
                    <p>Animated</p>
                </div>
            </div>
        </>
    );
};

export default Icons;
