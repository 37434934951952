import React from 'react';

const Lists = () => {
    return (
        <div className="row mb-5">
            <div className="col-12 col-lg-6">
                <ul className="list-group list-group-lg">
                    <li className="list-group-item list-group-item-branded d-flex justify-content-between align-items-center">
                        <svg
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-lg flex-shrink-0 mr-4">
                            <path
                                clipRule="evenodd"
                                d="M8 2.5C7.1 1.2 5.4.8 4 1.1 2.1 1.5.5 3 .5 5.6c0 2.7 2 6 7.2 9.3.2.1.4.1.5 0 5.3-3.3 7.2-6.6 7.2-9.3 0-2.6-1.6-4.2-3.5-4.5-1.3-.3-3 .1-3.9 1.4zm-3.8-.4c-1.4.3-2.7 1.4-2.7 3.5s1.6 5.1 6.5 8.3c4.9-3.2 6.5-6.2 6.5-8.3S13.2 2.3 11.8 2s-2.9.3-3.4 1.6c0 .3-.2.4-.4.4s-.4-.1-.5-.3c-.4-1.3-1.9-1.9-3.3-1.6z"
                                fillRule="evenodd"
                            />
                        </svg>
                        <span className="flex-grow-1">Minneslista</span>
                        <svg
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon">
                            <path
                                clipRule="evenodd"
                                d="M9.1 7.9L4.1 3l1.5-1.4 6.3 6.3-6.3 6.5L4.1 13z"
                                fillRule="evenodd"
                            />
                        </svg>
                    </li>
                    <li className="list-group-item list-group-item-light d-flex justify-content-between align-items-center">
                        <svg
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-lg flex-shrink-0 mr-4">
                            <path
                                clipRule="evenodd"
                                d="M8.5 1.4C8.4 1.1 8.2 1 8 1s-.4.1-.5.4L6.1 6H1.5c-.2 0-.4.1-.5.3 0 .2 0 .5.2.7l3.7 2.7-1.4 4.7c-.1.2 0 .5.2.6s.4.1.6 0L8 12l3.7 2.9c.2.1.4.1.6 0s.3-.4.2-.6l-1.4-4.7L14.8 7c.2-.2.2-.4.2-.6-.1-.2-.3-.4-.5-.4H9.9zM8 3L6.9 6.6c0 .2-.2.4-.5.4H3l2.8 2c.2.1.2.3.2.6L4.9 13l2.8-2.1c.2-.1.4-.1.6 0l2.8 2.1L10 9.5c0-.2 0-.4.2-.6L13 7H9.6c-.3 0-.5-.2-.5-.4z"
                                fillRule="evenodd"
                            />
                        </svg>
                        <span className="flex-grow-1">Favoriter</span>
                        <svg
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon">
                            <path
                                clipRule="evenodd"
                                d="M9.1 7.9L4.1 3l1.5-1.4 6.3 6.3-6.3 6.5L4.1 13z"
                                fillRule="evenodd"
                            />
                        </svg>
                    </li>
                    <li className="list-group-item list-group-item-light d-flex justify-content-between align-items-center">
                        <svg
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-lg flex-shrink-0 mr-4">
                            <path
                                clipRule="evenodd"
                                d="M3.1 1.1c.1 0 .3-.1.4-.1h9c.3 0 .5.2.5.5v13c0 .2-.1.4-.3.4-.2.1-.4.1-.5 0L8 12l-4.2 3h-.5c-.2-.1-.3-.3-.3-.5v-13c0-.1 0-.3.1-.4zM4 2v11.6L7.7 11c.2-.1.4-.1.6 0l3.7 2.6V2z"
                                fillRule="evenodd"
                            />
                        </svg>
                        <span className="flex-grow-1">Bevakningar</span>
                        <svg
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon">
                            <path
                                clipRule="evenodd"
                                d="M9.1 7.9L4.1 3l1.5-1.4 6.3 6.3-6.3 6.5L4.1 13z"
                                fillRule="evenodd"
                            />
                        </svg>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Lists;
