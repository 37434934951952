import React from 'react';

const Navigation = () => {
    return (
        <div className="row mb-5">
            <div className="col-12 col-lg-6 mb-lg-0 mb-5">
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className="nav-link active" href="/">
                            Active tab
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/">
                            Tab
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/">
                            Tab
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" href="/">
                            Disabled
                        </a>
                    </li>
                </ul>
            </div>
            <div className="col-12 col-lg-6">
                <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        <li className="page-item">
                            <a className="btn btn-outline-dark" href="/">
                                Previous
                            </a>
                        </li>
                        <li className="page-item active">
                            <a className="page-link" href="/">
                                1
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="/">
                                2
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="/">
                                3
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="/">
                                4
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="/">
                                5
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="/">
                                6
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="/">
                                7
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="/">
                                8
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="/">
                                ...
                            </a>
                        </li>
                        <li className="page-item disabled">
                            <a className="page-link" href="/">
                                10
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="btn btn-outline-dark" href="/">
                                Next
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default Navigation;
