import React, { Fragment } from 'react';

const Typography = () => {
    return (
        <Fragment>
            <div className="row mb-5">
                <div className="col-12 col-lg-6">
                    <h1 className="no-gatsby">Heading 1</h1>
                    <h2 className="no-gatsby">Heading 2</h2>
                    <h3 className="no-gatsby">Heading 3</h3>
                    <h4 className="no-gatsby">Heading 4</h4>
                    <h5 className="no-gatsby">Heading 5</h5>
                    <h6 className="no-gatsby">Heading 6</h6>
                </div>
                <div className="col-12 col-lg-6">
                    <h1 className="text-styled no-gatsby">H1 Branded</h1>
                    <h2 className="text-styled no-gatsby">H2 Branded</h2>
                    <h3 className="text-styled no-gatsby">H3 Branded</h3>
                    <h4 className="text-styled no-gatsby">H4 Branded</h4>
                    <h5 className="text-styled no-gatsby">H5 Branded</h5>
                    <h6 className="text-styled no-gatsby">H6 Branded</h6>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-12 col-lg-6">
                    <p className="text-inter-heavy">Heavy font weight</p>
                    <p className="text-inter-medium">Medium font weight</p>
                    <p className="text-inter-light">Light font weight</p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Phasellus quis porttitor nisi. Donec rhoncus id nibh sit
                        amet facilisis. Suspendisse potenti. Sed sed urna vel
                        diam convallis tincidunt id vitae magna. Praesent semper
                        pretium eros id fermentum. Integer lacinia tristique mi
                        id sodales. Vivamus ultricies, turpis ac imperdiet
                        varius, est ligula finibus urna, a posuere orci nibh eu
                        nulla. Nullam quis est a turpis egestas laoreet. Donec
                        neque tortor, malesuada eget pulvinar finibus, varius
                        eget ipsum. Cras varius in est non blandit. Integer in
                        vulputate massa. Quisque eu dictum turpis, faucibus
                        mattis tortor. Sed dictum posuere tortor sodales
                        fringilla. Ut in semper augue. Cras urna erat,
                        pellentesque in condimentum at, euismod ac elit.
                        Vestibulum nibh est, scelerisque quis sapien sit amet,
                        lobortis tincidunt ex.
                    </p>
                </div>
                <div className="col-12 col-lg-6">
                    <p>
                        <a href="/">Linked text</a>
                    </p>
                    <p>
                        You can use the mark tag to <mark>highlight</mark> text.
                    </p>
                    <p>
                        <del>
                            This line of text is meant to be treated as deleted
                            text.
                        </del>
                    </p>
                    <p>
                        <s>
                            This line of text is meant to be treated as no
                            longer accurate.
                        </s>
                    </p>
                    <p>
                        <ins>
                            This line of text is meant to be treated as an
                            addition to the document.
                        </ins>
                    </p>
                    <p>
                        <u>This line of text will render as underlined</u>
                    </p>
                    <p>
                        <small>
                            This line of text is meant to be treated as fine
                            print.
                        </small>
                    </p>
                    <p>
                        <strong>This line rendered as bold text.</strong>
                    </p>
                    <p>
                        <em>This line rendered as italicized text.</em>
                    </p>
                </div>
            </div>
        </Fragment>
    );
};

export default Typography;
