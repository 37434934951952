import React from 'react';

const FormControls = () => {
    return (
        <form>
            <div className="row mb-5">
                <div className="col-12 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="exampleInputText1">Text input</label>
                        <input
                            type="text"
                            className="form-control"
                            id="exampleInputText1"
                            placeholder="Text input"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputText2">Disabled</label>
                        <input
                            type="text"
                            disabled
                            className="form-control"
                            id="exampleInputText2"
                            placeholder="Disabled"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputTextValid">Valid</label>
                        <input
                            type="text"
                            className="form-control is-valid"
                            id="exampleInputTextValid"
                            placeholder="Valid"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputTextInvalid">Invalid</label>
                        <input
                            type="text"
                            className="form-control is-invalid"
                            id="exampleInputTextInvalid"
                            placeholder="Invalid"
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="exampleInputText3">Large</label>
                        <input
                            type="text"
                            className="form-control form-control-lg form-control-text-lg"
                            id="exampleInputText3"
                            placeholder="Text input"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputText4">Small</label>
                        <input
                            type="text"
                            className="form-control form-control-sm form-control-text-sm"
                            id="exampleInputText4"
                            placeholder="Text input"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputText5">Text area</label>
                        <textarea
                            className="form-control"
                            id="exampleInputText5"
                            placeholder="Text area"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlWithIcon1">
                            With icon
                        </label>
                        <div className=" form-control-with-icon">
                            <input
                                type="text"
                                id="exampleFormControlWithIcon1"
                                className="form-control"
                            />
                            <svg
                                className="icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 34 34">
                                <g fill="none" fillRule="evenodd">
                                    <path
                                        fill="#2D3742"
                                        d="M13.25 2c-3.102 0-5.752 1.1-7.951 3.299C3.099 7.498 2 10.149 2 13.25c0 3.102 1.1 5.752 3.299 7.951 2.199 2.2 4.85 3.299 7.951 3.299 2.2 0 4.201-.579 6.007-1.736l8.472 8.507A2.41 2.41 0 0 0 29.5 32a2.41 2.41 0 0 0 1.77-.73A2.41 2.41 0 0 0 32 29.5a2.41 2.41 0 0 0-.73-1.77l-8.506-8.473C23.92 17.45 24.5 15.449 24.5 13.25c0-3.102-1.1-5.752-3.299-7.951C19.002 3.099 16.351 2 13.25 2zm0 18.75c-2.06 0-3.825-.735-5.295-2.205-1.47-1.47-2.205-3.235-2.205-5.295s.735-3.825 2.205-5.295c1.47-1.47 3.235-2.205 5.295-2.205s3.825.735 5.295 2.205c1.47 1.47 2.205 3.235 2.205 5.295s-.735 3.825-2.205 5.295c-1.47 1.47-3.235 2.205-5.295 2.205z"
                                    />
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-12 col-lg-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="checkbox"
                            id="checkbox1"
                            defaultChecked
                        />
                        <label className="form-check-label" htmlFor="checkbox1">
                            Checkbox 1
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="checkbox"
                            id="checkbox2"
                        />
                        <label className="form-check-label" htmlFor="checkbox2">
                            Checkbox 2 with a very, very, very, very, very,
                            very, very, very, very, very, very long label
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="checkbox"
                            id="checkbox3"
                            disabled
                        />
                        <label className="form-check-label" htmlFor="checkbox3">
                            Checkbox disabled
                        </label>
                    </div>
                    <div className="form-check form-check-sm">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="checkbox"
                            id="checkbox4"
                        />
                        <label className="form-check-label" htmlFor="checkbox4">
                            Checkbox small
                        </label>
                    </div>
                </div>

                <div className="col-12 col-lg-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radio"
                            id="radio1"
                            defaultChecked
                        />
                        <label className="form-check-label" htmlFor="radio1">
                            Radio 1
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radio"
                            id="radio2"
                        />
                        <label className="form-check-label" htmlFor="radio2">
                            Radio 2
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radio"
                            id="radio3"
                            disabled
                        />
                        <label className="form-check-label" htmlFor="radio3">
                            Radio disabled
                        </label>
                    </div>
                    <div className="form-check form-check-sm">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="radio"
                            id="radio4"
                        />
                        <label className="form-check-label" htmlFor="radio4">
                            Radio small
                        </label>
                    </div>
                </div>
            </div>

            <div className="row mb-5 form-toggle">
                <input
                    type="checkbox"
                    className="form-toggle-input"
                    id="toggle"
                    value="1"
                />
                <label className="form-toggle-label" htmlFor="toggle">
                    Toggle
                </label>
            </div>

            <div className="row align-items-center mb-5">
                <div className="col-12 col-lg-3 mb-lg-0 mb-5">
                    <div className="form-control-radio-switch radio-switch-branded">
                        <fieldset className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                defaultChecked
                            />
                            <label
                                className="form-check-label"
                                htmlFor="inlineRadio1">
                                Alla
                            </label>
                        </fieldset>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="inlineRadio2">
                                Begagnat
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio3"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="inlineRadio3">
                                Nytt/Oanvänt
                            </label>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-3 mb-lg-0 mb-5">
                    <div className="form-control-radio-switch form-control-radio-switch-lg">
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions-lg"
                                id="inlineRadio1-lg"
                                defaultChecked
                            />
                            <label
                                className="form-check-label"
                                htmlFor="inlineRadio1-lg">
                                Samtliga
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions-lg"
                                id="inlineRadio2-lg"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="inlineRadio2-lg">
                                Kort tid kvar
                            </label>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-3 mb-lg-0 mb-5">
                    <div className="form-control-radio-switch">
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions-sm"
                                id="inlineRadio1-sm"
                                defaultChecked
                            />
                            <label
                                className="form-check-label"
                                htmlFor="inlineRadio1-sm">
                                Samtliga
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions-sm"
                                id="inlineRadio2-sm"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="inlineRadio2-sm">
                                Kort tid kvar
                            </label>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-3">
                    <div className="form-control-radio-switch">
                        <div className="form-check form-check-inline form-check-icon">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="iconRadioOptions"
                                id="iconRadio1"
                                value="option1"
                                defaultChecked
                            />
                            <label
                                className="form-check-label"
                                htmlFor="iconRadio1">
                                <svg
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon">
                                    <path
                                        clipRule="evenodd"
                                        d="M2 3c0-.6.4-1 1-1h1.2c.6 0 1 .4 1 1v1.2c0 .6-.4 1-1 1H3c-.6 0-1-.4-1-1zm0 4.4c0-.6.4-1 1-1h1.2c.6 0 1 .4 1 1v1.2c0 .6-.4 1-1 1H3c-.6 0-1-.4-1-1zm1 3.4c-.6 0-1 .4-1 1V13c0 .6.4 1 1 1h1.2c.6 0 1-.4 1-1v-1.2c0-.6-.4-1-1-1zM6.4 3c0-.6.4-1 1-1h1.2c.6 0 1 .4 1 1v1.2c0 .6-.4 1-1 1H7.4c-.6 0-1-.4-1-1zm1 3.4c-.6 0-1 .4-1 1v1.2c0 .6.4 1 1 1h1.2c.6 0 1-.4 1-1V7.4c0-.6-.4-1-1-1zm-1 5.4c0-.6.4-1 1-1h1.2c.6 0 1 .4 1 1V13c0 .6-.4 1-1 1H7.4c-.6 0-1-.4-1-1zM11.8 2c-.6 0-1 .4-1 1v1.2c0 .6.4 1 1 1H13c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1zm-1 5.4c0-.6.4-1 1-1H13c.6 0 1 .4 1 1v1.2c0 .6-.4 1-1 1h-1.2c-.6 0-1-.4-1-1zm1 3.4c-.6 0-1 .4-1 1V13c0 .6.4 1 1 1H13c.6 0 1-.4 1-1v-1.2c0-.6-.4-1-1-1z"
                                        fillRule="evenodd"
                                    />
                                </svg>
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="iconRadioOptions"
                                id="iconRadio2"
                                value="option2"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="iconRadio2">
                                <svg
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon">
                                    <path
                                        clipRule="evenodd"
                                        d="M3 2c-.6 0-1 .4-1 1v3c0 .5.4 1 1 1h3c.5 0 1-.4 1-1V3c0-.5-.4-1-1-1zm0 7c-.6 0-1 .4-1 1v3c0 .5.4 1 1 1h3c.5 0 1-.4 1-1v-3c0-.5-.4-1-1-1zm6-6c0-.5.4-1 1-1h3c.5 0 1 .4 1 1v3c0 .5-.4 1-1 1h-3c-.6 0-1-.4-1-1zm1 6c-.6 0-1 .4-1 1v3c0 .5.4 1 1 1h3c.5 0 1-.4 1-1v-3c0-.5-.4-1-1-1z"
                                        fillRule="evenodd"
                                    />
                                </svg>
                            </label>
                        </div>
                        <div className="form-check form-check-inline form-check-icon">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="iconRadioOptions"
                                id="iconRadio3"
                                value="option3"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="iconRadio3">
                                <svg
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon">
                                    <path
                                        clipRule="evenodd"
                                        d="M2 3c0-.6.4-1 1-1h10c.6 0 1 .4 1 1v10c0 .6-.4 1-1 1H3c-.6 0-1-.4-1-1z"
                                        fillRule="evenodd"
                                    />
                                </svg>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-12 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">
                            Select
                        </label>
                        <select
                            className="form-control single-select"
                            id="exampleFormControlSelect1">
                            <option>Dropdown</option>
                            <option>Högsta pris inklusive frakt</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlSelect2">
                            Select, rounded dark border
                        </label>
                        <select
                            className="form-control single-select form-control-rounded form-control-border-dark"
                            id="exampleFormControlSelect2">
                            <option>Dropdown</option>
                            <option>Högsta pris inklusive frakt</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select>
                    </div>
                </div>

                <div className="col-12 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="exampleFormControlSelect2">
                            Multiple select
                        </label>
                        <select
                            multiple
                            className="form-control"
                            id="exampleFormControlSelect2">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default FormControls;
